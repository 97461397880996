import { AvatarIconName, Message, UserMessage } from '@/types/chat';
import { FC, memo } from 'react';
import { UserChatMessage } from './UserChatMessage';
import { AssistantChatMessage } from './AssistantChatMessage';
import { Prompt } from '@/types/prompt';
import { OpenAIModel } from '@/types/openai';

interface Props {
  message: Message;
  messageIndex: number;
  loading: boolean;
  messageIsStreaming: boolean;
  textareaRef: any;
  prompts: Prompt[];
  aiAvatarId: AvatarIconName;
  onSend: (message: UserMessage) => void;
  model: OpenAIModel;
  handleScrollDown: () => void;
}

export const ChatMessage: FC<Props> = memo(
  ({
    message,
    messageIndex,
    loading,
    messageIsStreaming,
    textareaRef,
    prompts,
    aiAvatarId,
    onSend,
    model,
    handleScrollDown,
  }) => {
    return (
      <div
        className={`group text-gray-800`}
        // style={{ overflowWrap: 'anywhere' }}
      >
        <div className="relative m-auto w-full">
          {message.role === 'user' ? (
            <UserChatMessage
              message={message}
              messageIsStreaming={messageIsStreaming}
              loading={loading}
              textareaRef={textareaRef}
              onSend={onSend}
              prompts={prompts}
              model={model}
              handleScrollDown={handleScrollDown}
            />
          ) : (
            <AssistantChatMessage
              aiAvatarId={aiAvatarId}
              message={message}
              messageIndex={messageIndex}
              loading={loading}
              messageIsStreaming={messageIsStreaming}
            />
          )}
        </div>
      </div>
    );
  },
);
ChatMessage.displayName = 'ChatMessage';
