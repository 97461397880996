import CloseIcon from '@/public/icons/CloseThick.svg';
import ClientLogoIcon from '@/public/icons/ClientLogo.svg';
import { FC, useContext } from 'react';
import SubdomainContext from '@/context/SubdomainContext';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import { closeClickLink, logoClickLink } from '@/utils/sendAnalytics';
import { generateLinkWithUTMs } from '@/utils/app/url';

interface Props {
  onClearAll: () => void;
}

const gradientColorsToTransparent = {
  yellow_freshly:
    'bg-gradient-to-b from-[#FFCE13] via-[#FFEBA1] to-transparent',
  grey_groau: 'bg-gradient-to-b from-[#A8B3B2] to-transparent',
  yellow_planthia: 'bg-gradient-to-b from-[#F7DBE3] to-transparent',
  yellow_syra: 'bg-gradient-to-b from-[#EEEDBF] to-transparent',
  red_vicio: 'bg-gradient-to-b from-[#FF1E0C] to-transparent',
  green_jander: 'bg-gradient-to-b from-[#C1FCBC] to-transparent',
  green_baia_foods: 'bg-gradient-to-b from-[#8DB787] to-transparent',
  red_morfeo: 'bg-gradient-to-b from-[#D45743] to-transparent',
};

export const MenuHeader: FC<Props> = ({ onClearAll }) => {
  const subdomainConfig = useContext(SubdomainContext)!;
  const searchParams = useSearchParams();
  const redirectParam = searchParams.get('redirect');

  const validRedirectParam =
    subdomainConfig && redirectParam?.includes(subdomainConfig.name);

  let redirectLink = validRedirectParam
    ? redirectParam
    : subdomainConfig?.configuration.shopUrl;

  const gradientClassNames =
    subdomainConfig?.configuration.style.theme &&
    gradientColorsToTransparent[subdomainConfig?.configuration.style.theme]
      ? gradientColorsToTransparent[subdomainConfig?.configuration.style.theme]
      : gradientColorsToTransparent.yellow_freshly;

  const getMenuImage = (name: string, logoUrl: string) => (
    <Image
      alt={`${name}-logo`}
      src={logoUrl!}
      // solution source: https://github.com/vercel/next.js/discussions/18474#discussioncomment-5501724
      sizes="100vw"
      width={0}
      height={0}
      style={{ width: 'auto', height: '100%' }}
    />
  );

  const menuImageWrapper = (name: string, logoUrl: string, shopUrl?: string) =>
    shopUrl ? (
      <a
        onClick={() =>
          logoClickLink(
            generateLinkWithUTMs(shopUrl, window.location.host),
            subdomainConfig,
          )
        }
        target="_blank"
        href={generateLinkWithUTMs(shopUrl, window.location.host)}
        rel="noopener noreferrer"
      >
        {getMenuImage(name, logoUrl)}
      </a>
    ) : (
      <div className="relative h-[30px]">{getMenuImage(name, logoUrl)}</div>
    );

  const actionItemOne = (
    <div className="relative h-[20px]">
      <Image
        alt="clear-all-image"
        src={CloseIcon}
        // solution source: https://github.com/vercel/next.js/discussions/18474#discussioncomment-5501724
        sizes="100vw"
        width={0}
        height={0}
        style={{ width: 'auto', height: '100%' }}
      />
    </div>
  );

  return (
    <div
      className={`fixed top-0 left-0 z-50 flex w-full justify-center border-transparent ${gradientClassNames} py-md text-sm xl:py-xl`}
    >
      {subdomainConfig?.configuration.logoUrl ? (
        <div className="relative h-[30px]">
          {menuImageWrapper(
            subdomainConfig.name,
            subdomainConfig.configuration.logoUrl,
            subdomainConfig.configuration.shopUrl,
          )}
        </div>
      ) : (
        <ClientLogoIcon className="h-[30px] min-w-[107px] bg-transparent" />
      )}
      {redirectLink ? (
        // if there is a valid redirect queryparam
        <a
          onClick={() =>
            closeClickLink(
              generateLinkWithUTMs(redirectLink!, window.location.host),
              subdomainConfig,
            )
          }
          target="_blank"
          href={generateLinkWithUTMs(redirectLink!, window.location.host)}
          rel="noopener noreferrer"
          className="fixed right-0 mr-sm cursor-pointer p-[2px] hover:opacity-50 xl:mr-lg"
        >
          {actionItemOne}
        </a>
      ) : null}
    </div>
  );
};
