import { Chat } from '@/components/Chat/Chat';
import * as analytics from '@/utils/sendAnalytics';
import {
  AssistantMessage,
  ChatBody,
  Conversation,
  Message,
  UserMessage,
} from '@/types/chat';
import { KeyValuePair } from '@/types/data';
import { ErrorMessage } from '@/types/error';
import { OpenAIModelIDKey, OpenAIModels } from '@/types/openai';
import { Prompt } from '@/types/prompt';
import {
  cleanConversationHistory,
  cleanSelectedConversation,
} from '@/utils/app/clean';
import { DEFAULT_SYSTEM_PROMPT } from '@/utils/app/const';
import {
  saveConversation,
  saveConversations,
  updateConversation,
} from '@/utils/app/conversation';
import { GetServerSideProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import Footer from '@/components/Footer';
import { MenuHeader } from '@/components/MenuHeader';
import { BACKEND_HOST, BACKEND_API_KEY } from '@/utils/app/const';
import { SubdomainConfig } from '@/types/subdomain';
import { ParsedUrlQuery } from 'querystring';
import { SubdomainContextProvider } from '@/context/SubdomainContext';

interface HomeProps {
  serverSideApiKeyIsSet: boolean;
  subdomainConfig: SubdomainConfig;
}

const Home: React.FC<HomeProps> = ({
  serverSideApiKeyIsSet,
  subdomainConfig,
}) => {
  const defaultModelId: OpenAIModelIDKey =
    subdomainConfig.configuration.openAIModel;

  const { t } = useTranslation('chat');

  // STATE ----------------------------------------------

  const [apiKey, setApiKey] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [messageIsStreaming, setMessageIsStreaming] = useState<boolean>(false);

  const [modelError, setModelError] = useState<ErrorMessage | null>(null);

  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedConversation, setSelectedConversation] =
    useState<Conversation>();
  const [currentMessage, setCurrentMessage] = useState<UserMessage>();

  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [openAIModel] = useState(OpenAIModels[defaultModelId]);

  // REFS ----------------------------------------------

  // FETCH RESPONSE ----------------------------------------------

  const handleSend = async (message: UserMessage) => {
    if (selectedConversation) {
      let updatedConversation: Conversation;
      let currentUserMessageIndex: number =
        selectedConversation.messages.findLastIndex((m) => m.role === 'user');
      const previousMessages =
        currentUserMessageIndex > -1
          ? selectedConversation.messages.slice(0, currentUserMessageIndex)
          : selectedConversation.messages;
      const currentUserMessage =
        currentUserMessageIndex > -1
          ? selectedConversation.messages[currentUserMessageIndex]
          : message;

      updatedConversation = {
        ...selectedConversation,
        messages: [
          ...previousMessages,
          {
            ...currentUserMessage,
            content: message.content,
          } as UserMessage,
        ],
      };

      setSelectedConversation(updatedConversation);
      setLoading(true);
      setMessageIsStreaming(true);

      // TODO: prompt with variables

      const chatBody: ChatBody = {
        messages: updatedConversation.messages,
        subdomainId: subdomainConfig.id,
        // Chargeable: host doesn't include any substring
        host: window.location.host,
      };

      let body = JSON.stringify(chatBody);

      const controller = new AbortController();

      const lastMessageIdx = updatedConversation.messages.length - 1;
      analytics.userSendsMessage(
        (updatedConversation.messages[lastMessageIdx] as UserMessage).content,
        lastMessageIdx,
        subdomainConfig,
      );

      updatedConversation = {
        ...updatedConversation,
        messages: [
          ...updatedConversation.messages,
          {
            role: 'assistant',
            // if is first Message from assistant
            content: updatedConversation.messages.length <= 1 ? ['👋'] : [],
          },
        ],
      };
      setSelectedConversation(updatedConversation);

      const response = await fetch('/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        signal: controller.signal,
        body,
      });

      if (!response.ok) {
        setLoading(false);
        setMessageIsStreaming(false);
        toast.error(response.statusText);
        return;
      }

      const data = response.body;

      if (!data) {
        setLoading(false);
        setMessageIsStreaming(false);
        return;
      }

      setLoading(false);

      const reader = data.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let currentSentence = '';
      let nextSentence = '';
      let newMessage = false;
      let initMessageLine = true;

      analytics.assistantSendsMessageStart(
        '',
        lastMessageIdx + 1,
        subdomainConfig,
      );

      while (!done) {
        // sentences already submited
        if (newMessage) {
          newMessage = false;
        }
        if (nextSentence) {
          currentSentence = nextSentence;
          nextSentence = '';
        }

        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunkValue = decoder.decode(value);
        currentSentence += chunkValue;

        // to check if isEndOfSentence
        const indexOfEndline = currentSentence.search(
          // /(?:[.?!¿¡](?!\d\s)|:\n)\s*(?:\n|$)/,
          // /\/\/.*$/,
          /\r\n|\r|\n/,
        );

        let updatedMessages: Message[] = updatedConversation.messages;

        // check if the paragraph has finished
        if (!done) {
          if (indexOfEndline >= 0) {
            // nextSentence has to be assign before to avoid conflicts
            nextSentence = currentSentence.slice(indexOfEndline + 1);
            currentSentence = currentSentence.slice(0, indexOfEndline + 1);

            updatedMessages = updatedConversation.messages.map(
              (message, index) => {
                if (index === updatedConversation.messages.length - 1) {
                  return {
                    ...message,
                    content: initMessageLine
                      ? [
                          ...(message.content as string[]),
                          ...(currentSentence.trim()
                            ? [currentSentence.trim()]
                            : []),
                        ]
                      : (message.content as string[]).map((m, idx) => {
                          if (idx === message.content.length - 1) {
                            return currentSentence.trim();
                          }
                          return m;
                        }),
                  } as AssistantMessage;
                }

                return message;
              },
            );

            analytics.assistantSendsMessageBubble(
              currentSentence.trim(),
              lastMessageIdx + 1,
              updatedMessages[updatedMessages.length - 1].content.length, // partial_idx
              subdomainConfig,
            );

            currentSentence = '';
            newMessage = true;
            initMessageLine = true;
          } else {
            const numOpenParentheses = (currentSentence.match(/\(/g) || [])
              .length;
            const numCloseParentheses = (currentSentence.match(/\)/g) || [])
              .length;
            const numOpenBrackets = (currentSentence.match(/\[/g) || []).length;
            const numCloseBrackets = (currentSentence.match(/\]/g) || [])
              .length;
            const mismatch =
              numOpenParentheses !== numCloseParentheses ||
              numOpenBrackets !== numCloseBrackets;

            updatedMessages = updatedConversation.messages.map(
              (message, index) => {
                if (index === updatedConversation.messages.length - 1) {
                  return {
                    ...message,
                    content: initMessageLine
                      ? [
                          ...(message.content as string[]),
                          currentSentence.trim(),
                        ]
                      : (message.content as string[]).map((m, idx) => {
                          if (idx === message.content.length - 1) {
                            if (mismatch) {
                              // TODO: update texts
                              if (!m.endsWith('... *cargando enlace*'))
                                return m + '... *cargando enlace*';
                              return m;
                            }
                            return currentSentence.trim();
                          }
                          return m;
                        }),
                  } as AssistantMessage;
                }

                return message;
              },
            );
            initMessageLine = false;
          }
        } else {
          updatedMessages = updatedConversation.messages.map(
            (message, index) => {
              if (index === updatedConversation.messages.length - 1) {
                return {
                  ...message,
                  content: (message.content as string[]).map((m, idx) => {
                    if (idx === message.content.length - 1) {
                      return currentSentence.trim();
                    }
                    return m;
                  }),
                } as AssistantMessage;
              }

              return message;
            },
          );

          analytics.assistantSendsMessageBubble(
            currentSentence.trim(),
            lastMessageIdx + 1,
            updatedMessages[updatedMessages.length - 1].content.length, // partial_idx
            subdomainConfig,
          );
        }

        updatedConversation = {
          ...updatedConversation,
          messages: updatedMessages,
        };
        setSelectedConversation(updatedConversation);
      }
      analytics.assistantSendsMessageEnd(
        (
          updatedConversation.messages[
            updatedConversation.messages.length - 1
          ] as AssistantMessage
        ).content,
        lastMessageIdx + 1,
        subdomainConfig,
      );

      saveConversation(updatedConversation);

      const updatedConversations: Conversation[] = conversations.map(
        (conversation) => {
          if (conversation.id === selectedConversation.id) {
            return updatedConversation;
          }

          return conversation;
        },
      );

      if (updatedConversations.length === 0) {
        updatedConversations.push(updatedConversation);
      }

      setConversations(updatedConversations);
      saveConversations(updatedConversations);

      setMessageIsStreaming(false);
    }
  };

  // FETCH MODELS ----------------------------------------------

  const fetchModels = async (key: string = '') => {
    const error = {
      title: t('Error fetching models.'),
      code: null,
      messageLines: [
        t(
          'Make sure your OpenAI API key is set in the bottom left of the sidebar.',
        ),
        t('If you completed this step, OpenAI may be experiencing issues.'),
      ],
    } as ErrorMessage;

    const response = await fetch('/api/models', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        key: key || '',
      }),
    });

    if (!response.ok) {
      try {
        const data = await response.json();
        Object.assign(error, {
          code: data.error?.code,
          messageLines: [data.error?.message],
        });
      } catch (e) {}
      setModelError(error);
      return;
    }

    const data = await response.json();

    if (!data) {
      setModelError(error);
      return;
    }

    setModelError(null);
  };

  const handleUpdateConversation = (
    conversation: Conversation,
    data: KeyValuePair,
  ) => {
    const updatedConversation = {
      ...conversation,
      [data.key]: data.value,
    };

    const { single, all } = updateConversation(
      updatedConversation,
      conversations,
    );

    setSelectedConversation(single);
    setConversations(all);
  };

  // EFFECTS  --------------------------------------------

  useEffect(() => {
    if (currentMessage) {
      handleSend(currentMessage);
      setCurrentMessage(undefined);
    }
  }, [currentMessage]);

  useEffect(() => {
    if (selectedConversation && !messageIsStreaming) {
      const lastMessage =
        selectedConversation.messages[selectedConversation.messages.length - 1];
      if (lastMessage.role !== 'user') {
        let updatedConversation: Conversation = {
          ...selectedConversation,
          messages: [
            ...selectedConversation.messages,
            {
              role: 'user',
              content: '',
            } as UserMessage,
          ],
        };

        setSelectedConversation(updatedConversation);
        saveConversation(updatedConversation);
      }
    }
  }, [messageIsStreaming]);

  // ON LOAD --------------------------------------------

  useEffect(() => {
    const apiKey = localStorage.getItem('apiKey');
    if (serverSideApiKeyIsSet) {
      fetchModels('');
      setApiKey('');
      localStorage.removeItem('apiKey');
    } else if (apiKey) {
      setApiKey(apiKey);
    }

    const prompts = localStorage.getItem('prompts');
    if (prompts) {
      setPrompts(JSON.parse(prompts));
    }

    const conversationHistory = localStorage.getItem('conversationHistory');
    if (conversationHistory) {
      const parsedConversationHistory: Conversation[] =
        JSON.parse(conversationHistory);
      const cleanedConversationHistory = cleanConversationHistory(
        parsedConversationHistory,
      );
      setConversations(cleanedConversationHistory);
    }

    const selectedConversation = localStorage.getItem('selectedConversation');
    if (selectedConversation) {
      const parsedSelectedConversation: Conversation =
        JSON.parse(selectedConversation);
      const cleanedSelectedConversation = cleanSelectedConversation(
        parsedSelectedConversation,
      );
      setSelectedConversation(cleanedSelectedConversation);
    } else {
      setSelectedConversation({
        id: uuidv4(),
        name: 'New conversation',
        messages: [],
        prompt: DEFAULT_SYSTEM_PROMPT,
        folderId: null,
      });
    }
  }, [serverSideApiKeyIsSet]);

  useEffect(() => {
    // avoid situation with messageIsStreaming, it may cause an infinite loop
    if (selectedConversation && !messageIsStreaming) {
      const lastMessage =
        selectedConversation.messages[selectedConversation.messages.length - 1];
      if (
        selectedConversation.messages.length === 0 ||
        (selectedConversation.messages.length === 1 &&
          lastMessage.role === 'user' &&
          !lastMessage.content)
      )
        handleSend({
          role: 'user',
          content: subdomainConfig.configuration.onboardingMessage,
        });
      // if last message is not an empty content USER message, add blank userMessage with content '' it (probably from a previous version)
      else if (
        selectedConversation.messages.length > 1 &&
        selectedConversation.messages[selectedConversation.messages.length - 1]
          .role !== 'user'
      ) {
        setSelectedConversation({
          ...selectedConversation,
          messages: [
            ...selectedConversation.messages,
            { role: 'user', content: '' },
          ],
        });
      }
    }
  }, [selectedConversation]);

  // ACTIONS  --------------------------------------------

  const onClearAll = () => {
    if (
      selectedConversation &&
      confirm(t<string>('Are you sure you want to clear all messages?'))
    ) {
      analytics.clearConversation(subdomainConfig);
      handleUpdateConversation(selectedConversation, {
        key: 'messages',
        value: [],
      });
    }
  };

  return (
    <>
      <SubdomainContextProvider value={subdomainConfig}>
        {selectedConversation && (
          <main className={`flex h-screen w-screen justify-center text-sm`}>
            <MenuHeader onClearAll={onClearAll} />
            <div className="flex h-full w-full">
              <Chat
                config={{
                  aiAvatarId: subdomainConfig.configuration.aiAvatarId,
                }}
                model={openAIModel}
                conversation={selectedConversation}
                messageIsStreaming={messageIsStreaming}
                apiKey={apiKey}
                serverSideApiKeyIsSet={serverSideApiKeyIsSet}
                modelError={modelError}
                loading={loading}
                prompts={prompts}
                onSend={handleSend}
              />
            </div>
            <Footer />
          </main>
        )}
      </SubdomainContextProvider>
    </>
  );
};
export default Home;

interface QueryParams extends ParsedUrlQuery {
  subdomain_id?: string;
}

export const getServerSideProps: GetServerSideProps<any, QueryParams> = async ({
  req,
  params,
  query,
  res,
  locale,
}) => {
  const subdomainId = (query as QueryParams).subdomain_id;
  // This value is considered fresh for ten seconds (s-maxage=10).
  // If a request is repeated within the next 10 seconds, the previously
  // cached value will still be fresh. If the request is repeated before 59 seconds,
  // the cached value will be stale but still render (stale-while-revalidate=59).
  //
  // In the background, a revalidation request will be made to populate the cache
  // with a fresh value. If you refresh the page, you will see the new value.
  // res.setHeader(
  //   'Cache-Control',
  //   'public, s-maxage=10, stale-while-revalidate=59',
  // );




  const { host } = req.headers;
  const subdomainName =
    host && !host?.startsWith('localhost') && !host?.endsWith('vercel.app')
      ? host.split('.')[0]
      : 'freshly';
  const requestUrl = `${BACKEND_HOST}/api/subdomain/${subdomainName}?api_key=${BACKEND_API_KEY}${
    subdomainId ? `&subdomain_id=${subdomainId}` : ''
  }`;
  const response = await fetch(requestUrl, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  console.log('response', response.status);
  let subdomainConfig: SubdomainConfig | null = null;
  if (response.status < 300) subdomainConfig = await response.json();
  // console.log('response', subdomainConfig);
  if (!subdomainConfig)
    return {
      redirect: { destination: 'https://tryenso.com', statusCode: 302 },
    };

  return {
    props: {
      serverSideApiKeyIsSet: !!process.env.OPENAI_API_KEY,
      subdomainConfig,
      ...(await serverSideTranslations(locale ?? 'en', [
        'common',
        'chat',
        'sidebar',
        'markdown',
        'promptbar',
      ])),
    },
  };
};
