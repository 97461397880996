import SubdomainContext from '@/context/SubdomainContext';
import EnsoLogo from '@/public/icons/brand.svg';
import { useTranslation } from 'next-i18next';
import { useContext } from 'react';
import Image from 'next/image';
import { generateLinkWithUTMs } from '@/utils/app/url';

const gradientColorsFromTransparent = {
  yellow_freshly:
    'bg-gradient-to-b from-transparent via-[#FFEBA1] to-[#FFCE13]',
  grey_groau: 'bg-gradient-to-b from-transparent to-[#A8B3B2]',
  yellow_planthia: 'bg-gradient-to-b from-transparent to-[#F7DBE3]',
  yellow_syra: 'bg-gradient-to-b from-transparent to-[#EEEDBF]',
  red_vicio: 'bg-gradient-to-b from-transparent to-[#FF1E0C]',
  green_jander: 'bg-gradient-to-b from-transparent to-[#C1FCBC]',
  green_baia_foods: 'bg-gradient-to-b from-transparent to-[#8DB787]',
  red_morfeo: 'bg-gradient-to-b from-transparent to-[#D45743]',
};

function Footer() {
  const { t } = useTranslation('chat');
  const subdomainConfig = useContext(SubdomainContext);
  const gradientClassNames =
    subdomainConfig?.configuration.style.theme &&
    gradientColorsFromTransparent[subdomainConfig?.configuration.style.theme]
      ? gradientColorsFromTransparent[
          subdomainConfig?.configuration.style.theme
        ]
      : gradientColorsFromTransparent.yellow_freshly;
  const { host } = window.location;
  return (
    <div
      className={`fixed bottom-0 left-0 z-50 flex w-full justify-center space-x-modulor border-transparent ${gradientClassNames} py-xs text-b200`}
    >
      <p>{t('Powered by ')}</p>
      <a
        href={generateLinkWithUTMs('https://tryenso.com', host)}
        target="_blank"
        rel="noreferrer"
        className="underline"
      >
        <div className="my-[6px] flex w-[44px] min-w-[44px]">
          <Image
            alt={`enso-logo`}
            src={EnsoLogo}
            // solution source: https://github.com/vercel/next.js/discussions/18474#discussioncomment-5501724
            sizes="100vw"
            width={0}
            height={0}
            style={{ width: 'auto', height: '100%' }}
          />
        </div>
      </a>
    </div>
  );
}

export default Footer;
