export const generateLinkWithUTMs = (
  url: string,
  host: string,
  queryparams?: string,
) => {
  const newQueryParams = `${
    queryparams ? queryparams + '&' : ''
  }utm_source=${host}&utm_medium=${host}&utm_campaign=${host}`;
  return `${url}?${newQueryParams}`;
};
