import { FC, useContext } from 'react';
import { MemoizedReactMarkdown } from '../Markdown/MemoizedReactMarkdown';
import { onClickLink } from '@/utils/sendAnalytics';
import emojiRegex from 'emoji-regex';
import SubdomainContext from '@/context/SubdomainContext';
import { generateLinkWithUTMs } from '@/utils/app/url';

interface Props {
  content: string;
  messageIndex?: number;
}

const backgroundMessageBubble = {
  yellow_freshly: 'bg-[#CFE9FC]',
  grey_groau: 'bg-[#D3D3D3]',
  yellow_planthia: 'bg-[#ECEBE9]',
  yellow_syra: 'bg-[#E2E1DE]',
  red_vicio: 'bg-[#D3D3D3]',
  green_jander: 'bg-[#DEDCCF]',
  green_baia_foods: 'bg-[#D8DECF]',
  red_morfeo: 'bg-[#DED6CF]',
};

export const ChatMessageBubble: FC<Props> = ({ content, messageIndex }) => {
  const subdomainConfig = useContext(SubdomainContext)!;
  const bgClassNames =
    subdomainConfig?.configuration.style.theme &&
    backgroundMessageBubble[subdomainConfig?.configuration.style.theme]
      ? backgroundMessageBubble[subdomainConfig?.configuration.style.theme]
      : backgroundMessageBubble.yellow_freshly;
  const regex = emojiRegex();

  const hasOnlyOneEmoji =
    // @ts-ignore
    [...content.matchAll(regex)].length === 1 &&
    content.replaceAll(regex, '').trim().length === 0;

  return (
    <MemoizedReactMarkdown
      className={`prose rounded-t-2xl rounded-r-2xl ${bgClassNames} p-sm dark:prose-invert ${
        hasOnlyOneEmoji ? 'text-h200' : 'text-b300'
      }`}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');

          return (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
        table({ children }) {
          return (
            <table className="border-collapse border border-black px-3 py-1 dark:border-white">
              {children}
            </table>
          );
        },
        th({ children }) {
          return (
            <th className="break-words border border-black bg-gray-500 px-3 py-1 text-white dark:border-white">
              {children}
            </th>
          );
        },
        td({ children }) {
          return (
            <td className="break-words border border-black px-3 py-1 dark:border-white">
              {children}
            </td>
          );
        },
        a({ href, children, className }) {
          // To open links in a new tab
          const [baseUrl, queryparams] = (href || '').split('?');
          const { host } = window.location;
          return (
            <a
              onClick={() =>
                onClickLink(
                  baseUrl,
                  subdomainConfig,
                  content,
                  messageIndex,
                  children?.toString(),
                )
              }
              className={className}
              target="_blank"
              href={generateLinkWithUTMs(baseUrl, host, queryparams)}
            >
              {children}
            </a>
          );
        },
      }}
    >
      {content}
    </MemoizedReactMarkdown>
  );
};
