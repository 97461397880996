import { FC, useContext } from 'react';
import Image from 'next/image';
import MessageLoadingPlaceholderGIF from '@/public/MessageLoadingPlaceholder.gif';
import SubdomainContext from '@/context/SubdomainContext';

interface Props {}

const backgroundMessageBubble = {
  yellow_freshly: 'bg-[#CFE9FC]',
  grey_groau: 'bg-[#D3D3D3]',
  yellow_planthia: 'bg-[#ECEBE9]',
  yellow_syra: 'bg-[#E2E1DE]',
  red_vicio: 'bg-[#D3D3D3]',
  green_jander: 'bg-[#DEDCCF]',
  green_baia_foods: 'bg-[#D8DECF]',
  red_morfeo: 'bg-[#DED6CF]',
};

export const ChatLoader: FC<Props> = () => {
  const subdomainConfig = useContext(SubdomainContext);
  const bgClassNames =
    subdomainConfig?.configuration.style.theme &&
    backgroundMessageBubble[subdomainConfig?.configuration.style.theme]
      ? backgroundMessageBubble[subdomainConfig?.configuration.style.theme]
      : backgroundMessageBubble.yellow_freshly;
  return (
    <div className="flex space-x-xxs">
      <div className={`rounded-t-2xl rounded-r-2xl ${bgClassNames} p-sm`}>
        <Image
          alt="message-loading-placeholder"
          src={MessageLoadingPlaceholderGIF}
          width={35}
        />
      </div>
    </div>
  );
};
