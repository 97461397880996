import {
  AvatarIconName,
  Conversation,
  Message,
  UserMessage,
} from '@/types/chat';
import { ErrorMessage } from '@/types/error';
import { OpenAIModel } from '@/types/openai';
import { Prompt } from '@/types/prompt';
import { throttle } from '@/utils';

import { useTranslation } from 'next-i18next';

import { FC, memo, useEffect, useRef, useState } from 'react';
import { ChatMessage } from './ChatMessage';
import { ErrorMessageDiv } from './ErrorMessageDiv';

interface Props {
  conversation: Conversation;
  model: OpenAIModel;
  apiKey: string;
  serverSideApiKeyIsSet: boolean;
  messageIsStreaming: boolean;
  modelError: ErrorMessage | null;
  loading: boolean;
  prompts: Prompt[];
  config: { aiAvatarId: AvatarIconName };
  onSend: (message: UserMessage) => void;
}

export const Chat: FC<Props> = memo(
  ({
    conversation,
    model,
    messageIsStreaming,
    modelError,
    loading,
    prompts,
    config: { aiAvatarId },
    onSend,
  }) => {
    const { t } = useTranslation('chat');
    const [currentMessage, setCurrentMessage] = useState<Message>();
    const [autoScrollEnabled, setAutoScrollEnabled] = useState<boolean>(true);

    const messagesEndRef = useRef<HTMLDivElement>(null);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const handleScroll = () => {
      if (chatContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          chatContainerRef.current;
        const bottomTolerance = 30;

        if (scrollTop + clientHeight < scrollHeight - bottomTolerance) {
          setAutoScrollEnabled(false);
        } else {
          setAutoScrollEnabled(true);
        }
      }
    };

    const handleScrollDown = () => {
      chatContainerRef.current?.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    };

    const scrollDown = () => {
      if (autoScrollEnabled) {
        messagesEndRef.current?.scrollIntoView(true);
      }
    };
    const throttledScrollDown = throttle(scrollDown, 250);

    useEffect(() => {
      throttledScrollDown();
      setCurrentMessage(
        conversation.messages[conversation.messages.length - 1],
      );
    }, [conversation.messages, throttledScrollDown]);

    useEffect(() => {
      if (currentMessage?.role === 'assistant' && autoScrollEnabled) {
        setTimeout(handleScrollDown, 50);
      }
    }, [currentMessage?.content]);

    useEffect(() => {
      // on load messages scroll down
      setTimeout(handleScrollDown, 100);
    }, []);

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setAutoScrollEnabled(entry.isIntersecting);
          if (entry.isIntersecting) {
            textareaRef.current?.focus();
          }
        },
        {
          root: null,
          threshold: 0.5,
        },
      );
      const messagesEndElement = messagesEndRef.current;
      if (messagesEndElement) {
        observer.observe(messagesEndElement);
      }
      return () => {
        if (messagesEndElement) {
          observer.unobserve(messagesEndElement);
        }
      };
    }, [messagesEndRef]);

    {
      /* skip first message as it's to get status */
    }
    const messages = conversation.messages.slice(1);
    return (
      <div className="relative flex-1 overflow-hidden">
        {modelError ? (
          <ErrorMessageDiv error={modelError} />
        ) : (
          <div
            className="flex h-full w-full justify-center overflow-x-hidden scrollbar-hide"
            ref={chatContainerRef}
            onScroll={handleScroll}
          >
            <div className="w-full px-xs md:max-w-[544px] xl:max-w-[544px]">
              <div className="h-[78px] bg-transparent xl:h-[126px]" />
              <div className="justify-end space-y-lg">
                {messages.map((message, index) => {
                  const isLastElement = index === messages.length - 1;
                  let cleanedMessage = message;
                  if (
                    message.role === 'user' &&
                    message.content.includes('embedding results: ')
                  ) {
                    let splittedContent =
                      message.content.split('embedding results:');
                    let content = splittedContent[0].replace(
                      'user query: ',
                      '',
                    );
                    cleanedMessage.content = content;
                  }
                  return (
                    <ChatMessage
                      aiAvatarId={aiAvatarId}
                      key={index}
                      message={message}
                      messageIndex={index}
                      loading={isLastElement && loading}
                      messageIsStreaming={isLastElement && messageIsStreaming}
                      textareaRef={textareaRef}
                      onSend={onSend}
                      prompts={prompts}
                      model={model}
                      handleScrollDown={handleScrollDown}
                    />
                  );
                })}
              </div>
              <div className="h-14 bg-transparent" />
            </div>
          </div>
        )}
      </div>
    );
  },
);
Chat.displayName = 'Chat';
