import { SubdomainConfig } from '@/types/subdomain';

import { analytics } from '@/lib/segment';

const sendTrack = (
  eventId: string,
  eventName: string,
  subdomainConfig: SubdomainConfig,
  body: object,
) => {
  try {
    const { host } = window.location;
    const client = host.split('.')[0];
    const eventBody = {
      eventName,
      host,
      client,
      openAIModel: subdomainConfig.configuration.openAIModel,
      answerMaxTokens: subdomainConfig.configuration.answerMaxTokens,
      memoryChatMaxMessages:
        subdomainConfig.configuration.memoryChatMaxMessages,
      promptVersion: subdomainConfig.configuration.promptVersion,
      ...body,
    };
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
      analytics.track(eventId, eventBody);
    } else {
      console.log('[ANALYTICS]', eventId, eventBody);
    }
  } catch (e) {
    console.error(e);
  }
};

export const onClickLink = (
  url: string,
  subdomainConfig: SubdomainConfig,
  message?: string,
  idx_message?: number,
  linkText?: string,
) => {
  sendTrack('chat-link-clicked', 'Chat link clicked', subdomainConfig, {
    url,
    message,
    idx_message,
    linkText,
  });
};

export const closeClickLink = (
  url: string,
  subdomainConfig: SubdomainConfig,
) => {
  sendTrack('close-link-clicked', 'Close link clicked', subdomainConfig, {
    url,
  });
};

export const logoClickLink = (
  url: string,
  subdomainConfig: SubdomainConfig,
) => {
  sendTrack('logo-link-clicked', 'Logo link clicked', subdomainConfig, {
    url,
  });
};

export const userSendsMessage = (
  message: string,
  idx_message: number,
  subdomainConfig: SubdomainConfig,
) => {
  sendTrack('chat-user-sent_message', 'User message sent', subdomainConfig, {
    message,
    idx_message: idx_message - 2,
    message_length: message.length,
  });
};

export const assistantSendsMessageStart = (
  message: string,
  idx_message: number,
  subdomainConfig: SubdomainConfig,
) => {
  sendTrack(
    'chat-assistant-sent_message_start',
    'AI started generating message',
    subdomainConfig,
    {
      message,
      message_length: message.length,
      // avoid system user message and answer
      idx_message: idx_message - 2,
    },
  );
};

export const assistantSendsMessageBubble = (
  message: string,
  idx_message: number,
  idx_bubble: number,
  subdomainConfig: SubdomainConfig,
) => {
  sendTrack(
    'chat-assistant-sent_message_bubble',
    'AI bubble received',
    subdomainConfig,
    {
      message,
      message_length: message.length,
      // avoid system user message and answer
      idx_message: idx_message - 2,
      idx_bubble,
    },
  );
};

export const assistantSendsMessageEnd = (
  message: string[],
  idx_message: number,
  subdomainConfig: SubdomainConfig,
) => {
  sendTrack(
    'chat-assistant-sent_message_end',
    'AI message received',
    subdomainConfig,
    {
      message,
      message_length: message.join('').length,
      bubble_length: message.length,
      // avoid system user message and answer
      idx_message: idx_message - 2,
    },
  );
};

export const clearConversation = (subdomainConfig: SubdomainConfig) => {
  sendTrack('chat-conversation-clear', 'Chat cleaned', subdomainConfig, {});
};
