import { FC, useRef, useState, useEffect } from 'react';
import type { UserMessage } from '@/types/chat';
import { ChatInput } from './ChatInput';
import { useSpring, animated, useSpringRef } from '@react-spring/web';
import type { Prompt } from '@/types/prompt';
import type { OpenAIModel } from '@/types/openai';
import { Avatar } from './Avatar';

interface Props {
  message: UserMessage;
  messageIsStreaming: boolean;
  loading: boolean;
  textareaRef: any;
  prompts: Prompt[];
  onSend: (message: UserMessage) => void;
  model: OpenAIModel;
  handleScrollDown: () => void;
}

export const UserChatMessage: FC<Props> = ({
  message,
  messageIsStreaming,
  loading,
  textareaRef,
  onSend,
  prompts,
  model,
  handleScrollDown,
}) => {
  const [parentHeight, setParentHeight] = useState(100);
  const messageRef = useRef(null);
  const inputRef = useRef(null);

  const inputRefAPI = useSpringRef();
  const inputImperativeProps = useSpring({
    ref: inputRefAPI,
    from: { opacity: 0, marginTop: '5rem' },
  });
  const messageRefAPI = useSpringRef();
  const messageImperativeProps = useSpring({
    ref: messageRefAPI,
    from: { opacity: 0, marginTop: '5rem' },
  });

  const updateInputOpacity = (opacity: number) => {
    inputRefAPI.start({
      to: { opacity, marginTop: '0em' },
      config: { duration: 300 },
    });
  };

  const updateMessageOpacity = (
    opacity: number,
    immediate: boolean = false,
  ) => {
    messageRefAPI.start({
      to: { opacity, marginTop: '0em' },
      config: { duration: 300 },
      immediate,
    });
  };

  useEffect(() => {
    if (message.content) {
      updateMessageOpacity(1, true);
      setParentHeight(
        // @ts-ignore
        messageRef.current?.clientHeight,
      );
    } else {
      updateInputOpacity(1);
      setParentHeight(
        // @ts-ignore
        inputRef.current?.clientHeight,
      );
    }
  }, []);

  useEffect(() => {
    if (message.content) {
      updateMessageOpacity(1);
      updateInputOpacity(0);
      setParentHeight(
        // @ts-ignore
        messageRef.current?.clientHeight,
      );
    } else {
      updateInputOpacity(1);
      updateMessageOpacity(0);
      setParentHeight(
        // @ts-ignore
        inputRef.current?.clientHeight,
      );
    }
  }, [message.content]);

  const pressSend = (message: UserMessage) => {
    onSend(message);
    setParentHeight(
      // @ts-ignore
      messageRef.current?.clientHeight,
    );
  };

  const updateInputHeight = () => {
    // @ts-ignore
    if (inputRef.current?.clientHeight !== parentHeight) {
      setParentHeight(
        // @ts-ignore
        inputRef.current?.clientHeight,
      );
      setTimeout(() => {
        handleScrollDown();
      }, 0);
    }
  };

  const messageDiv = (
    <div className="flex justify-end space-x-xxs" ref={messageRef}>
      <div className="prose rounded-t-2xl rounded-l-2xl bg-base-1000 p-sm">
        {message.content}
      </div>
      <Avatar iconName="user_1" />
    </div>
  );

  const inputDiv = (
    <div className="flex justify-end space-x-xxs" ref={inputRef}>
      <ChatInput
        textareaRef={textareaRef}
        messageIsStreaming={messageIsStreaming}
        model={model}
        prompts={prompts}
        onSend={pressSend}
        updateParentHeight={updateInputHeight}
      />
      <Avatar iconName="user_1" />
    </div>
  );
  return (
    <div style={{ height: parentHeight }}>
      <div className="absolute w-full">
        <animated.div style={messageImperativeProps}>{messageDiv}</animated.div>
      </div>
      <div className="absolute w-full">
        <animated.div style={inputImperativeProps}>{inputDiv}</animated.div>
      </div>
    </div>
  );
};
