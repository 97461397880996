import type { UserMessage } from '@/types/chat';
import { OpenAIModel } from '@/types/openai';
import { Prompt } from '@/types/prompt';
import { useTranslation } from 'next-i18next';
import {
  FC,
  KeyboardEvent,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ArrowRightIcon from '@/public/icons/ArrowRight.svg';
import SubdomainContext from '@/context/SubdomainContext';
import Image from 'next/image';

interface Props {
  messageIsStreaming: boolean;
  model: OpenAIModel;
  prompts: Prompt[];
  onSend: (message: UserMessage) => void;
  textareaRef: MutableRefObject<HTMLTextAreaElement | null>;
  updateParentHeight: () => void;
}

const backgroundColorThemes = {
  yellow_freshly: 'text-base-100 bg-[#FFCE13]',
  grey_groau: 'text-[#FFFEFD] bg-[#28423E] fill-[#FFFEFD]',
  yellow_planthia: 'text-[#FFFEFD] bg-[#545454] fill-[#FFFEFD]',
  yellow_syra: 'text-[#FFFEFD] bg-[#0F0F0F] fill-[#FFFEFD]',
  red_vicio: 'text-base-100 bg-[#FF1E0C]',
  green_jander: 'text-base-100 bg-[#C1FCBC]',
  green_baia_foods: 'text-base-100 bg-[#8DB787]',
  red_morfeo: 'text-base-100 bg-[#D45743]',
};

const spinnerColorThemes = {
  yellow_freshly: 'border-neutral-800',
  grey_groau: 'border-neutral-100',
  yellow_planthia: 'border-neutral-100',
  yellow_syra: 'border-neutral-100',
  red_vicio: 'border-neutral-800',
  green_jander: 'border-neutral-800',
  green_baia_foods: 'border-neutral-800',
  red_morfeo: 'border-neutral-800',
};

export const ChatInput: FC<Props> = ({
  messageIsStreaming,
  model,
  prompts,
  onSend,
  textareaRef,
  updateParentHeight,
}) => {
  const subdomainConfig = useContext(SubdomainContext);
  const themeName =
    subdomainConfig?.configuration.style.theme || 'yellow_freshly';
  const bgClassNames =
    backgroundColorThemes[themeName] || backgroundColorThemes.yellow_freshly;
  const spinnerClassNames =
    spinnerColorThemes[themeName] || spinnerColorThemes.yellow_freshly;
  const { t } = useTranslation('chat');

  const [content, setContent] = useState<string>();
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [showPromptList, setShowPromptList] = useState(false);
  const [activePromptIndex, setActivePromptIndex] = useState(0);
  const [promptInputValue, setPromptInputValue] = useState('');
  const [sendClicked, setSendClicked] = useState(false);

  const promptListRef = useRef<HTMLUListElement | null>(null);

  const filteredPrompts = prompts.filter((prompt) =>
    prompt.name.toLowerCase().includes(promptInputValue.toLowerCase()),
  );

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const maxLength = model.maxLength;

    if (value.length > maxLength) {
      alert(
        t(
          `Message limit is {{maxLength}} characters. You have entered {{valueLength}} characters.`,
          { maxLength, valueLength: value.length },
        ),
      );
      return;
    }

    setContent(value);
    updatePromptListVisibility(value);
  };

  const recommendationsHook = async (
    messageContent: string,
    subdomainId: string,
  ) => {
    const response = await fetch('/api/recommendations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query: messageContent, subdomainId }),
    });
    let recommendationsMessage = '';
    if (!response.ok) {
      recommendationsMessage = 'No recommendations found';
    }
    const data = await response.json();
    if (data) recommendationsMessage = data.recommendationsMessage;

    return (
      `user query: ${messageContent}` +
      '\n' +
      `embedding results: ${recommendationsMessage}`
    );
  };

  const handleSend = async () => {
    if (messageIsStreaming) {
      return;
    }

    if (!content) {
      alert(t('Please enter a message'));
      return;
    }
    setSendClicked(true);
    // const extendedContent = await recommendationsHook(
    //   content,
    //   subdomainConfig?.id!,
    // );

    onSend({ role: 'user', content });
    setContent('');

    if (window.innerWidth < 640 && textareaRef && textareaRef.current) {
      textareaRef.current.blur();
    }
  };

  const isMobile = () => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobileRegex =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i;
    return mobileRegex.test(userAgent);
  };

  const handleInitModal = () => {
    const selectedPrompt = filteredPrompts[activePromptIndex];
    if (selectedPrompt) {
      setContent((prevContent) => {
        const newContent = prevContent?.replace(
          /\/\w*$/,
          selectedPrompt.content,
        );
        return newContent;
      });
    }
    setShowPromptList(false);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (showPromptList) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setActivePromptIndex((prevIndex) =>
          prevIndex < prompts.length - 1 ? prevIndex + 1 : prevIndex,
        );
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setActivePromptIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex,
        );
      } else if (e.key === 'Tab') {
        e.preventDefault();
        setActivePromptIndex((prevIndex) =>
          prevIndex < prompts.length - 1 ? prevIndex + 1 : 0,
        );
      } else if (e.key === 'Enter') {
        e.preventDefault();
        handleInitModal();
      } else if (e.key === 'Escape') {
        e.preventDefault();
        setShowPromptList(false);
      } else {
        setActivePromptIndex(0);
      }
    } else if (e.key === 'Enter' && !isTyping && !isMobile() && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const updatePromptListVisibility = useCallback((text: string) => {
    const match = text.match(/\/\w*$/);

    if (match) {
      setShowPromptList(true);
      setPromptInputValue(match[0].slice(1));
    } else {
      setShowPromptList(false);
      setPromptInputValue('');
    }
  }, []);

  useEffect(() => {
    if (promptListRef.current) {
      promptListRef.current.scrollTop = activePromptIndex * 30;
    }
  }, [activePromptIndex]);

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${textareaRef.current?.scrollHeight}px`;
      textareaRef.current.style.overflow = `${
        textareaRef?.current?.scrollHeight > 400 ? 'auto' : 'hidden'
      }`;
      updateParentHeight();
    }
  }, [content]);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        promptListRef.current &&
        !promptListRef.current.contains(e.target as Node)
      ) {
        setShowPromptList(false);
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="flex w-full flex-row">
      <div className="flex w-full flex-grow flex-col space-y-xs rounded-2xl bg-base-1000 p-sm">
        <textarea
          ref={textareaRef}
          className="flex w-full resize-none rounded-lg bg-base-600 p-xs text-b300 text-base-100 text-opacity-40 shadow-none focus:text-opacity-100 focus:shadow-[0px_0px_6px] focus:shadow-primary focus:outline-none"
          style={{
            bottom: `${textareaRef?.current?.scrollHeight}px`,
          }}
          placeholder={t('Type a message') || ''}
          value={content}
          rows={1}
          onCompositionStart={() => setIsTyping(true)}
          onCompositionEnd={() => setIsTyping(false)}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />

        <button
          className={`flex w-fit rounded-lg ${bgClassNames} py-tiny px-xs`}
          onClick={handleSend}
        >
          {sendClicked ? (
            <div
              className={`h-4 w-4 animate-spin rounded-full border-t-2 ${spinnerClassNames} opacity-60`}
            ></div>
          ) : (
            <div className="mr-1 flex space-x-tiny text-b300 font-bold">
              <div className="my-[4px] h-[16px]">
                <Image
                  alt="arrow-rigth-icon"
                  src={ArrowRightIcon}
                  // solution source: https://github.com/vercel/next.js/discussions/18474#discussioncomment-5501724
                  sizes="100vw"
                  width={0}
                  height={0}
                  style={{
                    width: 'auto',
                    height: '100%',
                    ...{
                      filter: bgClassNames.includes('fill-[#FFFEFD]')
                        ? 'invert(100%)'
                        : undefined,
                    },
                  }}
                />
              </div>
              <p className="">{t('Send')}</p>
            </div>
          )}
        </button>
      </div>
    </div>
  );
};
