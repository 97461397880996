export interface OpenAIModel {
  id: string;
  name: string;
  maxLength: number; // maximum length of a message
  tokenLimit: number;
}

export enum OpenAIModelID {
  GPT_3_5 = 'gpt-3.5-turbo',
  GPT_4 = 'gpt-4',
  GPT_4_TURBO = 'gpt-4-turbo',
  GPT_4O = 'gpt-4o',
  GPT_4_MINI = 'gpt-4o-mini'
}

// in case the `DEFAULT_MODEL` environment variable is not set or set to an unsupported model
export const fallbackModelID = OpenAIModelID.GPT_3_5;

export type OpenAIModelIDKey = 'GPT_3_5' | 'GPT_4' | 'GPT_4_TURBO' | 'GPT_4O' | 'GPT_4_MINI';

export const OpenAIModels: Record<OpenAIModelIDKey, OpenAIModel> = {
  GPT_3_5: {
    id: OpenAIModelID.GPT_3_5,
    name: 'GPT-3.5',
    maxLength: 12000,
    tokenLimit: 4000,
  },
  GPT_4: {
    id: OpenAIModelID.GPT_4,
    name: 'GPT-4',
    maxLength: 24000,
    tokenLimit: 8000,
  },
  GPT_4_TURBO: {
    id: OpenAIModelID.GPT_4_TURBO,
    name: 'GPT-4_Turbo',
    maxLength: 512000,
    tokenLimit: 128000,
  },
  GPT_4O: {
    id: OpenAIModelID.GPT_4O,
    name: 'GPT-4O',
    maxLength: 512000,
    tokenLimit: 128000,
  },
  GPT_4_MINI: {
    id: OpenAIModelID.GPT_4_MINI,
    name: 'GPT-4O',
    maxLength: 512000,
    tokenLimit: 128000,
  },
};
