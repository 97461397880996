import { FC } from 'react';
import type { AssistantMessage, AvatarIconName } from '@/types/chat';
import { ChatLoader } from './ChatLoader';
import { ChatMessageBubble } from './ChatMessageBubble';
import { useTransition, animated } from '@react-spring/web';
import { Avatar } from './Avatar';

interface Props {
  message: AssistantMessage;
  loading: boolean;
  messageIsStreaming: boolean;
  messageIndex: number;
  aiAvatarId: AvatarIconName;
}

export const AssistantChatMessage: FC<Props> = ({
  message,
  loading,
  messageIsStreaming,
  messageIndex,
  aiAvatarId,
}) => {
  const messages = Array.isArray(message.content)
    ? message.content
    : (message.content as unknown as string).split('\n').filter((x) => !!x);

  // const transitions = useTransition(messages, {
  //   from: { opacity: 0, height: 0, marginTop: '5rem' },
  //   enter: { opacity: 1, height: 'auto', marginTop: '0rem' },
  //   config: { duration: 300 },
  // });

  return (
    <div className={`flex space-x-xxs`}>
      <Avatar iconName={aiAvatarId} />
      <div className="space-y-xs">
        {messages.map((contentPiece) => (
          <div className="flex" key={Math.random()}>
            <ChatMessageBubble
              content={contentPiece}
              messageIndex={messageIndex}
              key={Math.random()}
            />
          </div>
        ))}
        {loading && <ChatLoader />}
      </div>
    </div>
  );
};
