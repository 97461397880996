import { FC, useContext } from 'react';
import UserIcon from '@/public/icons/User.svg';
import AssistantMale01 from '@/public/icons/Assistant/Male-01.svg';
import AssistantMale02 from '@/public/icons/Assistant/Male-02.svg';
import AssistantMale03 from '@/public/icons/Assistant/Male-03.svg';
import AssistantMale04 from '@/public/icons/Assistant/Male-04.svg';
import AssistantMale05 from '@/public/icons/Assistant/Male-05.svg';
import AssistantMale06 from '@/public/icons/Assistant/Male-06.svg';
import AssistantFemale01 from '@/public/icons/Assistant/Female-01.svg';
import AssistantFemale02 from '@/public/icons/Assistant/Female-02.svg';
import AssistantFemale03 from '@/public/icons/Assistant/Female-03.svg';
import AssistantFemale04 from '@/public/icons/Assistant/Female-04.svg';
import AssistantFemale05 from '@/public/icons/Assistant/Female-05.svg';
import AssistantFemale06 from '@/public/icons/Assistant/Female-06.svg';
import AssistantFemale07 from '@/public/icons/Assistant/Female-07.svg';
import AssistantFemale08 from '@/public/icons/Assistant/Female-08.svg';
import AssistantFemale09 from '@/public/icons/Assistant/Female-09.svg';
import AssistantFemale10 from '@/public/icons/Assistant/Female-10.svg';
import AssistantFemale11 from '@/public/icons/Assistant/Female-11.svg';
import AssistantFemale12 from '@/public/icons/Assistant/Female-12.svg';
import AssistantFemaleSegle01 from '@/public/icons/Assistant/Female-Segle-01.png';
import AssistantFemaleSegle02 from '@/public/icons/Assistant/Female-Segle-02.png';
import { AvatarIconName } from '@/types/chat';
import SubdomainContext from '@/context/SubdomainContext';

import Image from 'next/image';

interface Props {
  iconName: 'user_1' | AvatarIconName;
}

const backgroundColorThemes = {
  yellow_freshly: 'bg-[#F7FE7C]',
  grey_groau: 'bg-[#8B948A]',
  yellow_planthia: 'bg-[#F7DBE3]',
  yellow_syra: 'bg-[#EEEDBF]',
  red_vicio: 'bg-[#FF1F0D]',
  green_jander: 'bg-[#C1FCBC]',
  green_baia_foods: 'bg-[#8DB787]',
  red_morfeo: 'bg-[#D45743]',
};

export const Avatar: FC<Props> = ({ iconName = 'user' }) => {
  const subdomainConfig = useContext(SubdomainContext);
  const bgClassNames =
    subdomainConfig?.configuration.style.theme &&
    backgroundColorThemes[subdomainConfig?.configuration.style.theme]
      ? backgroundColorThemes[subdomainConfig?.configuration.style.theme]
      : backgroundColorThemes.yellow_freshly;
  let Icon;
  let iconNameFirst = iconName.split('-')[0];
  switch (iconNameFirst) {
    case 'user_1':
      Icon = UserIcon;
      break;
    case 'assistant_male_1':
      Icon = AssistantMale01;
      break;
    case 'assistant_male_2':
      Icon = AssistantMale02;
      break;
    case 'assistant_male_3':
      Icon = AssistantMale03;
      break;
    case 'assistant_male_4':
      Icon = AssistantMale04;
      break;
    case 'assistant_male_5':
      Icon = AssistantMale05;
      break;
    case 'assistant_male_6':
      Icon = AssistantMale06;
      break;
    case 'assistant_female_1':
      Icon = AssistantFemale01;
      break;
    case 'assistant_female_2':
      Icon = AssistantFemale02;
      break;
    case 'assistant_female_3':
      Icon = AssistantFemale03;
      break;
    case 'assistant_female_4':
      Icon = AssistantFemale04;
      break;
    case 'assistant_female_5':
      Icon = AssistantFemale05;
      break;
    case 'assistant_female_6':
      Icon = AssistantFemale06;
      break;
    case 'assistant_female_7':
      Icon = AssistantFemale07;
      break;
    case 'assistant_female_8':
      Icon = AssistantFemale08;
      break;
    case 'assistant_female_9':
      Icon = AssistantFemale09;
      break;
    case 'assistant_female_10':
      Icon = AssistantFemale10;
      break;
    case 'assistant_female_11':
      Icon = AssistantFemale11;
      break;
    case 'assistant_female_12':
      Icon = AssistantFemale12;
      break;
    case 'assistant_custom_segle_01':
      Icon = AssistantFemaleSegle01;
      break;
    case 'assistant_custom_segle_02':
      Icon = AssistantFemaleSegle02;
      break;
    default:
      Icon = AssistantFemale02;
      break;
  }

  return (
    <div className="flex flex-col justify-end">
      <div
        className={`my-[2px] h-[56px] min-w-[56px]${
          iconName.toLowerCase().includes('assistant')
            ? ` ${bgClassNames} rounded-full`
            : ''
        }`}
      >
        {/* eslint-disable-next-line react/jsx-no-undef */}
        <Image
          alt={`${
            iconName.toLowerCase().includes('assistant') ? 'assistant' : 'user'
          }-avatar`}
          src={Icon}
          className="circular-gradient"
          // solution source: https://github.com/vercel/next.js/discussions/18474#discussioncomment-5501724
          sizes="100vw"
          width={0}
          height={0}
          style={{ width: 'auto', height: '100%' }}
        />
      </div>
    </div>
  );
};
